export class UserManualModel{
    ManulId:number;
    ManulName:string;
    ManulPath:number;
    CreatedBy:string;
    CreatedDate:string;
    ModifiedBy:string;
    ModifiedDate:string;
}

export class RichTextModel{
    RichTextId:number;
    Type:string;
    Contents:number;
    CreatedBy:string;
    CreatedDate:string;
    ModifiedBy:string;
    ModifiedDate:string;
}

export class ErrUserManualModel{
    ManulName:string;
}

export class ValidFormModel{
    IsValidForm:boolean;//验证是否通过
    ErrModel:any;
}

export class ErrModel{
    OldPassword:string;
    NewPassword:String;
}