import { Component, OnInit, ViewChild } from '@angular/core';
//import { MiddleComponent } from './middle/middle.component';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html'
})

export class ErrorComponent implements OnInit {

 // @ViewChild(MiddleComponent) menus: MiddleComponent;

  
  constructor(
      private route: ActivatedRoute,
      //private middle:MiddleComponent
  ) { }

  ErrorMessage=null;
  ngOnInit() {
      //标题设置
      // this.middle.ModuleTitle="ErrorPage";
      // this.middle.SubTitle="Message";

      this.ErrorMessage=decodeURIComponent(this.route.snapshot.queryParamMap.get("ErrorMessage"));
      if(this.ErrorMessage==null||this.ErrorMessage==''){
        this.ErrorMessage="Error !"
      }
    
  }

}
