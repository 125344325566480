import { Injectable} from '@angular/core';
import {HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import {AbpApiService,Result} from '../../core/services/abp-api-service';
import {UserManualModel,ErrUserManualModel, RichTextModel} from './header.model';
import { observableToBeFn } from 'rxjs/testing/TestScheduler';
import { PagedData } from '../../Model/page.model';
import {ValidFormModel,OptionModel} from '../../Model/common.model';
import { NzMessageService } from 'ng-zorro-antd';
@Injectable()
export class HeaderService  extends AbpApiService{
    constructor(protected http: HttpClient,private Nzmessage: NzMessageService) {super(http,Nzmessage); }
    /**
   * Job 获取详情
   */
  public GetManualList():Observable<UserManualModel[]>{
    return this.abpGet<UserManualModel[]>(this.SERVER_URL+"/api/UserManual/UserManual/GetManualList");
  }
  public GetContactList():Observable<string>{
    return this.abpGet<string>(this.SERVER_URL+"/api/UserManual/UserManual/GetContactList");
  }
  public GetNoticesList():Observable<string>{
    return this.abpGet<string>(this.SERVER_URL+"/api/UserManual/UserManual/GetNoticesList");
  }
  
  public DownLoad(ManulId:string):Observable<any>{
    return this.abpGet<any>(this.SERVER_URL+"/api/UserManual/UserManual/GetFileFromNetUrl?manualId="+ManulId+"&Confirm=0");
  }

  public ChangePassword(input:any):Observable<any>{
    return this.abpPost<any>(this.SERVER_URL+"/api/AccountApi/ChangePassword",input);
  }

  public Logout():Observable<any>{
    return this.abpPost<any>(this.SERVER_URL+"/api/AccountApi/Logout");
  }
}