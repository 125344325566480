import { Injectable} from '@angular/core';
import {HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import {AbpApiService,Result} from '../../core/services/abp-api-service';
import { observableToBeFn } from 'rxjs/testing/TestScheduler';
import { PagedData } from '../../Model/page.model';
import {ValidFormModel,OptionModel} from '../../Model/common.model';
import { NzMessageService } from 'ng-zorro-antd';
@Injectable()
export class MiddleService  extends AbpApiService{
    constructor(protected http: HttpClient,private Nzmessage: NzMessageService) {super(http,Nzmessage); }
}