import { Component,ViewChild, OnInit,TemplateRef,Input, Output, EventEmitter} from '@angular/core';
import { NzNotificationService,NzModalService,NzSubMenuComponent  } from 'ng-zorro-antd';
import { Router,NavigationEnd,ActivatedRoute  } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import {filter, map} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  @Input() params:any;
  @Output() private outer = new EventEmitter<any>();

  isCollapsed = false;
  isReverseArrow  = false;
  width = 219;

  curUrl = '';
  nodes = [];
  CurrentPermissions=[];
  pastURL = '';
  IsErrorModule=false;
  IsOpen=[];
  MenuList=[];

  constructor(
    private http: HttpClient,
    private router: Router, 
    private sanitizer: DomSanitizer,
    private notification: NzNotificationService,
    private modalService :NzModalService,
    private route:ActivatedRoute
  ) { }

  ngOnInit() {
    const base = this;
    this.outer.emit({
      Option:"ChangeWidth",
      Data:{
        MenuWidth:this.width
      }
    });

    this.GetAppAccountInfo();

    this.router.events
    .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.router)
    )
    .subscribe((event) => {

      this.SetRouter(event.url);
       
      // 切换路由置顶
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    });
  }

  GetAppAccountInfo() {
    var url = environment.SERVER_URL + '/api/Account/GetMenus';
    if(this.route.snapshot.queryParams['IsDirectURL'])
    {
      url+="?IsDirectURL="+this.route.snapshot.queryParams['IsDirectURL'];
    }
    let token=localStorage.getItem("token");
    if(!token){
      window.location.href="";
      return;
    }
    this.http.get(url,{ headers: { Token: token }})
    .subscribe(res => {
      if(res["State"]===1)
      {
         sessionStorage.setItem('UserAccount', res['Data'].User); 
        if(res['Data'].Menus)
        {

          const base = this;
          //初始化当前用户权限
          if(res['Data'].Permissions)
          {
            base.CurrentPermissions = res['Data'].Permissions;
          //   base.CurrentPermissions.push({
          //     "PermissionId": 14,
          //     "PermissionName": "PSSId",
          //     "PermissionText": "PSSId",
          //     "ParentId": 1,
          //     "Url": "pss/list",
          //     "IndexNo": 0,
          //     "Remark": null,
          //     "Icon": "anticon anticon-profile",
          //     "IsMenu": true,
          //     "IsDelete": false
          // });
            //console.info(base.CurrentPermissions);

            ///////
            this.outer.emit({
              Option:"InitPermission",
              Data:{
                CurrentPermissions:base.CurrentPermissions
              }
            });

            base.MenuList=base.CurrentPermissions.filter(m=>m.IsMenu);
            base.MenuList.filter(m=>m.IsMenu).forEach(function(item,index){
              base.IsOpen[item.PermissionId]=false;
            });
          }

            base.nodes = res['Data'].Menus;

            if(base.nodes)
            {
              //获取第一个子菜单
              base.GetFirstLeaf(base.nodes);
              //console.info(this.firstNode.Permissions.Url);
              sessionStorage.setItem('FirstURL',this.firstNode.Permissions.Url); 
            }

            if(this.route.snapshot.queryParams['IsDirectURL'] && this.route.snapshot.queryParams['IsDirectURL']=="1")
            {
              //从其他系统带参进入，直接进入需要的页面
              base.SetRouter(this.router.url);
              //location.href=location.href;
            }else{
              //默认加载第一个子菜单
              base.GoRouter(this.firstNode.Permissions.Url);
            }
            
          }

        }else{
          //location.href = '#/error?'+this.parseParams({ErrorMessage:res["Message"]});
        }
      },err=> {
        
/*           if(err.error.ErrorMessage)
          { 
            location.href = '#/error?'+this.parseParams({ErrorMessage:err.error.ErrorMessage});
          }else{
            location.href = '#/error?'+this.parseParams({ErrorMessage:err.message});
          }
          return err; */
      });
  }

  //获取第一个叶子节点
  firstNode=null;
  GetFirstLeaf(node){
    if(this.firstNode!=null)
    {
      return ; //如果res不再是空对象，退出递归;
    }else{
      //遍历数组
      for (let i = 0; i < node.length; i++) {
          //如果当前的isleaf无子菜单,说明是叶子节点，把当前对象赋值给res,并return，终止循环
          if (node[i].SubPermissions.length==0) {
              this.firstNode = node[i];
              return;
          } else if (!node[i].SubPermissions) {//如果chidren为空，则停止这次循环
              break;
          } else {//否则的话，递归当前节点的children
              this.GetFirstLeaf(node[i].SubPermissions);
          }
      }

    }
  }

  parseParams(params: any): HttpParams {
      let ret = new HttpParams();
      if (params) {
        // tslint:disable-next-line:forin
        for (const key in params) {
          let _data = params[key];
          // 将时间转化为：时间戳 (秒)
          if (moment.isDate(_data)) {
            _data = moment(_data).unix();
          }
          ret = ret.set(key, _data);
        }
      }
      return ret;
    }

    CollapsedChange(){
      if(this.isCollapsed)
      {
        this.width=66;
      }else
      {
        this.width=219;
      }
      
      this.outer.emit({
        Option:"ChangeWidth",
        Data:{
          MenuWidth:this.width
        }
      });
    }
  
    //  url是否在菜单中
    IsInMenuList(Url)
    {
      const base = this;
      var IsFlag=false;
      if (base.MenuList && base.MenuList.length) 
      {
            base.MenuList.forEach(function(item,index){
              if(Url.indexOf(item.Url)>=0)
              {
                IsFlag= true;
              }
            });
      }
      return IsFlag;
    }
  
    //设置路由对应菜单样式
    SetRouter(Url){
      const base = this;
      if (base.MenuList && base.MenuList.length) 
      {
        if(base.IsInMenuList(Url))
        {
          for(var key in base.IsOpen){
            base.IsOpen[key]=false;
          }
          var cNode=base.MenuList.filter(m=>Url.indexOf(m.Url)>=0)[0];
          base.IsOpen[cNode.PermissionId]=true;
          if(cNode)
          {
            while(cNode.ParentId!=0)
            {
              cNode=base.MenuList.filter(m=>m.PermissionId==cNode.ParentId)[0];
              base.IsOpen[cNode.PermissionId]=true;
            }
          }
        }
            
      }
    }
  
    //路由跳转
    GoRouter(Url) {//subMenu
      const base = this;
      if (base.nodes && base.nodes.length) 
      {
        base.SetRouter(Url);
        //base.router.navigate(["/report/task-list/1"],{ queryParams: { TableType: '121' }});   
        //base.router.navigateByUrl('').then(() => {
          base.router.navigate(["/"+Url]);
          // //初始化当前用户权限
          // if(sessionStorage.getItem('CurrentPermissions'))
          // {
          //   base.CurrentPermissions = JSON.parse(sessionStorage.getItem('CurrentPermissions'));
          // }
        //});
  
      }
  
    }
  
    //父菜单切换
    SetMenuIsSelected(data){
      const base = this;
      for(var key in base.IsOpen){
        base.IsOpen[key]=false;
      }
      var cNode=data;
      base.IsOpen[cNode.PermissionId]=true;
      if(cNode)
      {
        while(cNode.ParentId!=0)
        {
          cNode=base.MenuList.filter(m=>m.PermissionId==cNode.ParentId)[0];
          base.IsOpen[cNode.PermissionId]=true;
        }
      }
    }

}
