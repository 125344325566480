import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { HeaderComponent } from './header/header.component';
import { MiddleComponent } from './middle/middle.component';
import { ErrorComponent } from './error.component';
import { HeaderService } from "./header/header.service";
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { MenuComponent } from './menu/menu.component';
import { AuthInterceptor } from '../app-auth-interceptor.module';
import {HttpClientModule,HTTP_INTERCEPTORS} from '@angular/common/http';
import { MiddleService } from './middle/middle.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgZorroAntdModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  declarations: [LayoutComponent, HeaderComponent, MiddleComponent, ErrorComponent, MenuComponent],
  providers:[
    HeaderService,
    MiddleService,
    HeaderComponent,
    MiddleComponent,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
})
export class LayoutModule { }

