import { Component, OnInit,TemplateRef,Renderer2,ElementRef } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {
  MenuWidth=219;
  CurrentPermissions=[];
  layoutParams={
    Menu:{},
    Middle:{}
  }
  IsInit=false;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {

   }

  ngOnInit() {
  }

  ngAfterViewChecked(){
    this.setWidth();
  }

  //menu 回调
  runParentMenu(Res) {
    if (Res.Option == "InitPermission")
    {
      this.CurrentPermissions=Res.Data.CurrentPermissions;
      this.layoutParams.Middle={CurrentPermissions:this.CurrentPermissions};
    }
    else if (Res.Option == "ChangeWidth") { 
      this.MenuWidth=Res.Data.MenuWidth;
      this.setWidth();
    }
  }

  //设置 breadcrumb 宽度
  setWidth()
  {
    var _this=this;
    var breadcrumb=_this.el.nativeElement.querySelector('.breadcrumb');
      //document.getElementsByClassName("breadcrumb")[0].w;
    if(breadcrumb)
    {
      _this.renderer.setStyle(breadcrumb,'width',"calc(100% - "+_this.MenuWidth+"px)");
      // breadcrumb.forEach(function(item,index){
        
      //   _this.renderer.setStyle(item,'width',"calc(100% - "+_this.MenuWidth+"px)");
      // });
    }
  }

}
