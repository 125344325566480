import { ApiBaseService } from './api-base-service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { MiddleComponent } from '../../layout/middle/middle.component'
declare var $: any;
//import { Router } from '@angular/router';

/**
 * 进一步封装HttpClient，主要解决：
 * 后台apb框架返回数据的解析
 */
export abstract class AbpApiService extends ApiBaseService {

  private middle: MiddleComponent;
  //private router: Router;
  //private modalService: NzModalService;
  constructor(
    protected http: HttpClient,
    private message: NzMessageService
  ) {
    super(http);
  }

  abpGet<T>(url: string, params?: any, headers?: any): Observable<any> {
    this.encodeParams(params);
    let token=localStorage.getItem("token")||"";
    if (headers) {
      headers.Token = token;
    } else {
      headers = { Token: token }
    }
    return this.get(url, params, headers).map(r => {
      return this.process<T>(r);
    });
  }

  abpPost<T>(url: string, body?: any, params?: any, headers?: any): Observable<any> {
    //this.encodeParams(body);
    // console.info(body);    
    let token=localStorage.getItem("token")||"";
    if (headers) {
      headers.Token = token;
    } else {
      headers = { Token: token }
    }
    return this.post(url, body, params, headers).map(r => {
      return this.process<T>(r);
    });
  }

  /**
   * 由各个子模块处理回传数据
   * @param url
   * @param body
   * @param params
   * @param headers
   */
  abpPost2<T>(url: string, body?: any, params?: any, headers?: any): Observable<any> {
    return this.post(url, body, params, headers).map((r: T) => {
      return r;
    });
  }

  /**
   * 参数超长，用post获取数据
   * @param url
   * @param body
   * @param params
   * @param headers
   */
  abpGet2<T>(url: string, body?: any, params?: any, headers?: any): Observable<any> {
    body = JSON.parse(JSON.stringify(body)); // 克隆对象
    this.encodeParams(body);
    return this.post(url, $.param(body), params, headers).map((r: T) => {
      return this.process<T>(r);
    });
  }

  abpDownload(url: string, fileName: string, callback = () => { }, body?: any, params?: any, headers?: any,
    contentType = "application/octet-stream") {
    let token = localStorage.getItem("token") || "";
    if (headers) {
      headers.Token = token;
    } else {
      headers = { Token: token }
    }
    this.http.post(url, body, {
      params: this.parseParams(params),
      responseType: "blob",
      headers: headers
    }).subscribe(res => {
      //.*（ 二进制流，不知道下载文件类型）	application/octet-stream
      //xls	application/vnd.ms-excel
      //更多类型参考 https://www.runoob.com/http/http-content-type.html

      let blob = new Blob([res], { type: contentType });
      let url = window.URL.createObjectURL(blob);

      // 以动态创建a标签进行下载
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      callback();
    },err=>{
      callback();
    });
  }

  private process<T>(r: any): any {
    const data = r as Result;
    // if (data['code'] == '800') {
    //   //this.modalService.closeAll();
    //   this.message.warning(data['message'],{'nzDuration':3000});
    //   window.location.href = '#/home';
    //   throw "800";
    //   //return;
    // }
    // else if (data['code'] == '401') {
    //   //this.modalService.closeAll();
    //   console.info(data['message']);
    //   window.location.href = '#/error?ErrorMessage='+encodeURIComponent(data['message']);
    //   throw "401";
    //   //return;
    // }

    if (data.State == 1) {
      return data.Data as T;
    } else if (data.State == 0) {
      this.message.warning(data.Message);
      throw data.Message;
    } else {
      this.message.error(data.Message);
      throw data.Message;
    }
  }

  /**
   * encode传递的参数
   * @param inParameters
   */
  private encodeParams(inParameters: any): void {
    if (inParameters) {
      for (const key in inParameters) {
        if (!inParameters[key]) {
          inParameters[key] = '';
        }
        else if (typeof inParameters[key] === 'string') {
          if (inParameters[key].trim() == '') {
            inParameters[key] = '';
          } else {
            inParameters[key] = encodeURIComponent(inParameters[key].trim());
          }
          //inParameters[key] = encodeURIComponent(inParameters[key]).replace(/+/g,"%2B");
        }
      }
    }
  }
}

// 后台返回的结构体
export class Result {
  State: number;
  Message: any;
  Data: any;
}
