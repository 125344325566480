import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ShareService } from "../share.service";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { ValidFormModel } from '../../Model/common.model';

@Component({
  selector: 'app-common-setting-header',
  templateUrl: './common-setting-header.component.html',
  styleUrls: ['./common-setting-header.component.css']
})
export class CommonSettingHeaderComponent implements OnInit {
  @Input() params: any;
  @Output() private outer = new EventEmitter<any>();
  isVisibleHeader = false;
  dataHeaderSet = [];
  allHeaderChecked = false;
  indeterminateHeader = false;
  CheckBoxHeaderTable = [];
  isOkHeaderLoading = false;
  paramsP: any = {};
  seletHeaderList = [];
  model: any = {};
  errModel: any = {};
  TableType:0;

  constructor(
    private shareService: ShareService,
    private fb: FormBuilder,
    private router: Router,
    private message: NzMessageService,
    private modalService: NzModalService
  ) { }

  ngOnInit() {
    this.TableType=this.params.TableType;
    this.HeaderList();
  }

  //#region 设置表头
  HeaderList() {
    this.isVisibleHeader = true;
    this.shareService.HeaderList({
      TableType: this.TableType
    }).subscribe(r => {
      this.dataHeaderSet = r.items;
      r.items.forEach(iteam => {
        // if (iteam.Checked == true) {
        //   this.CheckBoxHeaderTable.push(iteam);
        // }
        this.seletHeaderList.push(iteam);
      });
    });
  }
  handleHeaderCancel() {
    //this.isVisibleHeader = false;
    this.isOkHeaderLoading = false;
    this.outer.emit({ isVisible: false, Option: "CancelToSetting" });
  }
  nzHeaderCheckedChange(nzChecked: boolean, data: any) {
    if (nzChecked) {
      this.CheckBoxHeaderTable.push(data);
    }
    else {
      var indexC = this.CheckBoxHeaderTable.indexOf(data.UserFieldId);
      if (indexC > -1) {
        this.CheckBoxHeaderTable.splice(indexC, 1);
      }
    }
    this.refreshHeaderStatus();
  }
  checkHeaderAll(value: boolean): void {
    this.dataHeaderSet.forEach(data => {
      if (value) {
        this.CheckBoxHeaderTable.push(data);
      }
      else {
        var indexC = this.CheckBoxHeaderTable.indexOf(data.UserFieldId);
        if (indexC > -1) {
          this.CheckBoxHeaderTable.splice(indexC, 1);
        }
      }
      data.Checked = value
    });
    this.refreshHeaderStatus();
  }
  refreshHeaderStatus(): void {
    const allHeaderChecked = this.dataHeaderSet.every(value => value.Checked === true);
    const allHeaderUnChecked = this.dataHeaderSet.every(value => !value.Checked);
    this.allHeaderChecked = allHeaderChecked;
    this.indeterminateHeader = (!allHeaderChecked) && (!allHeaderUnChecked);
  }
  handleHeaderOk() {
    this.isOkHeaderLoading = true;
    this.errModel = {};
    this.shareService.SaveToUserFields(
      { 
        CheckList: JSON.stringify(this.dataHeaderSet) 
      }, 
      this.TableType
    ).subscribe(r => {
      this.errModel = r.ErrModel;
      if (r.IsValidForm)//验证通过，保存
      {
        //this.isVisibleHeader = false;
        this.message.success('Operation successfully !', { nzDuration: 3000 });
        this.outer.emit({ isVisible: false, Option: "SetColumnSetting" });
      } else {
        this.message.error(r.ErrModel, { nzDuration: 3000 });
      }
      this.isOkHeaderLoading = false;
    }, err => {
      //返回报错时关闭loading
      this.isOkHeaderLoading = false;
    })
  }
  //#endregion

}
