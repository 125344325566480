import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { HeaderService } from "./header.service";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { UserManualModel, RichTextModel, ErrUserManualModel, ErrModel } from './header.model';
import { MiddleComponent } from '../middle/middle.component';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @ViewChild('HelpTemplate') HelpTemplate: TemplateRef<{}>;
  constructor(
    private headerService: HeaderService,
    private fb: FormBuilder,
    private router: Router,
    private message: NzMessageService,
    private modalService: NzModalService,
    private middle: MiddleComponent
  ) { }

  userManuanList = [];

  isVisible = {
    Help: false,
    Notice: false,
    Account: false
  };
  validateForm: FormGroup;
  umodel = new UserManualModel();
  rmodel = new RichTextModel();

  noticeContent = "";

  UserName: string = "";
  ContactList: string = "";

  SystemVersion = environment.SystemVersion;

  accountForm: FormGroup;
  paramOldPassword: string;
  paramNewPassword: string;
  paramNewPassword2: string;
  isBP: boolean;
  needChangePassword: boolean = false;

  errModel = new ErrModel();

  ToShowHelp() {
    this.isVisible.Help = true;
    this.umodel = new UserManualModel();
    this.userManuanList = [];
    this.headerService.GetManualList().subscribe(r => {
      if (r) {
        this.userManuanList = r;
      }
    });
    this.headerService.GetContactList().subscribe(m => {
      if (m) {
        this.ContactList = m;
      }
    });

  }

  handleCancelHelp() {
    this.isVisible.Help = false;
  }

  ToShowNotice() {
    this.isVisible.Notice = true;
    this.headerService.GetNoticesList().subscribe(m => {
      if (m) {
        this.noticeContent = m;
      }
    });

  }
  handleCancelNotice() {
    this.isVisible.Notice = false;
  }

  ToShowAccount() {
    this.isVisible.Account = true;
    this.accountForm.reset();
  }
  handleCancelAccount() {
    this.isVisible.Account = false;
    this.errModel = new ErrModel();
  }
  ChangePassword() {
    this.errModel = new ErrModel();
    for (const i in this.accountForm.controls) {
      this.accountForm.controls[i].markAsDirty();
      this.accountForm.controls[i].updateValueAndValidity();
    }

    if (this.accountForm.status == "VALID") {
      this.headerService.ChangePassword({
        OldPassword: this.paramOldPassword,
        NewPassword: this.paramNewPassword
      }).subscribe(p => {
        this.errModel = p.ErrModel;
        if (p.IsValidForm) {
          this.errModel = new ErrModel();
          this.message.success("修改成功");
          localStorage.removeItem("needChangePassword");
          this.handleCancelAccount();
        }
      })
    }
  }

  confirmPassword = () => {
    if (!this.paramNewPassword2) {
      return { required: true };
    } else if (this.paramNewPassword !== this.paramNewPassword2) {
      return { confirm: true };
    }
  }

  Logout() {
    this.headerService.Logout().subscribe(p=>{
      localStorage.removeItem("token");
      window.location.href = "/";
    })
  }

  DownLoad(ManulId) {
    this.headerService.DownLoad(ManulId).subscribe(m => {
      window.location.href = environment.SERVER_URL + "/api/UserManual/UserManual/GetFileFromNetUrl?manualId=" + ManulId + "&Confirm=1";
    })
  }
  ngOnInit() {
    //刷新用户名
    this.middle.ModuleTitle = "Usermanual Information";
    this.middle.SubTitle = "List";
    this.isBP = localStorage.getItem("source") == "1";
    this.validateForm = this.fb.group({
      ManulName: [this.umodel.ManulName, []],
      Contents: [this.rmodel.Contents, []]
    });
    this.accountForm = this.fb.group({
      paramOldPassword: [this.paramOldPassword, [Validators.required]],
      paramNewPassword: [this.paramNewPassword, [Validators.required]],
      paramNewPassword2: [this.paramNewPassword2, [Validators.required, this.confirmPassword]]
    });
    this.needChangePassword = localStorage.getItem('needChangePassword') === "true";
    if (this.needChangePassword) {
      this.isVisible.Account = true;
    }
    const intervalId = setInterval(() => {
      if (this.UserName == null || this.UserName == "") {
        this.UserName = localStorage.getItem('userName');
      } else {
        clearInterval(intervalId);
      }
    }, 60);
  }

}
