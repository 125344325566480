import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import {FormBuilder,FormControl,FormGroup,Validators} from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import {NzMessageService,NzModalService} from 'ng-zorro-antd';

@Component({
  selector: 'app-templete-simple-edit',
  templateUrl: './templete-simple-edit.component.html',
  styleUrls: ['./templete-simple-edit.component.css']
})
export class TempleteSimpleEditComponent implements OnInit {

  @Input() params:any;
  @Output() private outer = new EventEmitter<any>();
  isOkLoading = false;
  
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private message:NzMessageService,
    private modalService :NzModalService
  ) { }

  ngOnInit() {
  }

  handleOk(): void {
    this.message.success('Operation successfully !', { nzDuration: 3000 });
    this.outer.emit({isVisible:false,Option:"Save",result:"sucess"});
  }

  handleCancel(): void {
    this.isOkLoading=false;
    this.outer.emit({isVisible:false,Option:"Cancel"});
  }

}
