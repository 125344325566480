import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/of';
@Injectable()

//自定义预加载策略-指定模块预加载
export class CustomPreloadingStrategy implements PreloadingStrategy {
    preloadedModules: string[] = [];
  
    preload(route: Route, load: () => Observable<any>): Observable<any> {
      if (route.data && route.data['preload']) {
        return load();
      } else {
        return Observable.of(null);
      }
    }
  }