import { NgModule,CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { routes } from "./routes"
import {LayoutModule} from "../layout/layout.module";
import {ShareModule} from "../share/share.module";
import { CommunicateService } from '../communicate.service';
import { CustomPreloadingStrategy } from '../custom-preloading-strategy';

@NgModule({
  imports: [
    CommonModule,
    LayoutModule,
    ShareModule,
    RouterModule.forRoot(routes, {  
      preloadingStrategy: CustomPreloadingStrategy, // 预加载
      useHash: true 
    }), // 这个定义在子模块中，但是是跟路由，EQBPInfoComponentEqbpinfoListComponentFunnelPickerListComponentAreaListComponentBackupMainEqComponentBackupMainEquipmentComponent我们使用forRoot
  ],
  declarations: [],
  schemas:[
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  providers:[ 
    CommunicateService,
    CustomPreloadingStrategy
  ]
})
export class RoutesModule { }
