import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgZorroAntdModule,NZ_I18N, en_US } from 'ng-zorro-antd';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { RoutesModule } from "./routes/routes.module"
import { HttpClientModule , HTTP_INTERCEPTORS } from '@angular/common/http';
//import { CommunicateService } from './communicate.service';
import { LocationStrategy, HashLocationStrategy, PathLocationStrategy } from '@angular/common';
import { DragulaModule } from 'ng2-dragula';
import { AuthInterceptor } from './app-auth-interceptor.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    RouterModule,
    BrowserModule,
    NgZorroAntdModule.forRoot(),
    DragulaModule.forRoot(),
    RoutesModule,
    BrowserAnimationsModule,
    HttpClientModule
  ],
  providers:[ 
    { provide: LocationStrategy, useClass: HashLocationStrategy },   
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    //CommunicateService,
    { 
      provide: NZ_I18N, 
      useValue: en_US 
    } 
  ], // 这里设置当前全局使用的语言包
  bootstrap: [AppComponent]
})
export class AppModule { }
