import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
//import { POFService } from ".././pof.service";
import { ShareService } from "../share.service";
import {FormBuilder,FormControl,FormGroup,Validators} from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import {NzMessageService,NzModalService} from 'ng-zorro-antd';

@Component({
  selector: 'app-select-customer-list',
  templateUrl: './select-customer-list.component.html',
  styleUrls: ['./select-customer-list.component.css']
})
export class SelectCustomerListComponent implements OnInit {

  constructor(
    private ShareService: ShareService,
    private fb: FormBuilder,
    private router: Router,
    private message:NzMessageService,
    private modalService :NzModalService
  ) { }
  @Input() params: any;
  @Output() private outer = new EventEmitter<any>();
  // 搜索参数
  searchForm: FormGroup;
  searchParams:any={};
  CheckedCustomerNo='';
  pageIndex = 1;
  pageSize = 50;
  total = 1;
  dataSet = [];
  loading = false;
  isOkLoading=false
  scrollConfig={};


  
  Columns=[
    {ColumnKey:"CustomerNo",ColumnName:"客户号",IsShow:true,width:100},
    {ColumnKey:"CustomerName",ColumnName:"客户中文名称",IsShow:true,width:200},
    {ColumnKey:"CustomerNameEN",ColumnName:"客户英文名称",IsShow:true,width:300},
    {ColumnKey:"ListName",ColumnName:"List Name",IsShow:true,width:300},
    {ColumnKey:"BlockName",ColumnName:"Sales Block",IsShow:true,width:150},
    {ColumnKey:"Region",ColumnName:"大区",IsShow:true,width:100},
    {ColumnKey:"SubRegion",ColumnName:"小区",IsShow:true,width:100},
    {ColumnKey:"Province",ColumnName:"省",IsShow:true,width:100},
    {ColumnKey:"City",ColumnName:"市",IsShow:true,width:120},
    {ColumnKey:"Street",ColumnName:"Street",IsShow:true,width:300},
    {ColumnKey:"HospitalClass",ColumnName:"医院等级",IsShow:true,width:100},
    {ColumnKey:"Sector",ColumnName:"Sector",IsShow:true,width:100},
    {ColumnKey:"CustomerSegment",ColumnName:"CS Customer Segment",IsShow:true,width:250},
    {ColumnKey:"CSVIPType",ColumnName:"CS VIP分类",IsShow:true,width:100},
    {ColumnKey:"LowMargin",ColumnName:"Low Margin",IsShow:true,width:100}
  ];

  //列表查询
  GetPageList(pIndex:number)
  {
    this.pageIndex=pIndex;
    var valid = true;
        for (const i in this.searchForm.controls) {
            if (true) {
                this.searchForm.controls[i].markAsDirty();
                this.searchForm.controls[i].updateValueAndValidity();
                if (this.searchForm.controls[i].valid === false) {
                  valid = false;
                }
            }
        }
        if(valid)
        {
          this.loading = true;
          this.ShareService.GetPageCustomerList(
            {
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
                CustomerNo: this.searchParams.CustomerNo,
                CustomerName:this.searchParams.CustomerName,
                BlockName:this.searchParams.BlockName,
                Source:this.searchParams.Source
            }).subscribe(p => {
                this.loading = false;
                this.total = p.totalCount;
                this.dataSet = p.items;
              },err=>{
              //返回报错时关闭loading
              this.loading=false;
            });
        }
  };


  resetForm()
  {
    this.searchForm.reset();
    this.GetPageList(1);
  }

  ngOnInit() {
    var _this=this;
    
    _this.searchParams.CustomerNo = this.params.CustomerNo ? this.params.CustomerNo:"";
    _this.searchParams.CustomerName = this.params.CustomerName ? this.params.CustomerName:"";
    _this.searchParams.BlockName = this.params.BlockName ? this.params.BlockName:"";
    _this.searchParams.Source = this.params.Source ? this.params.Source:"";
    //console.info(_this.searchParams);

    _this.searchForm = _this.fb.group({
      CustomerNo:[ this.searchParams.CustomerNo, [] ],
      CustomerName:[ this.searchParams.CustomerName, [] ],
      BlockName:[ this.searchParams.BlockName, [] ]
    });

    _this.GetPageList(1);
    //设置表格高度
    //表头列宽求和，再加35px的checkbox宽度
    _this.scrollConfig={x: (eval(_this.Columns.filter(m =>m.IsShow).map(m=>m.width).join("+"))+35)+"px", y: ((document.body.clientHeight - 460) + "px")};

  }

  handleCancel(): void {
    this.loading=false;
    this.isOkLoading=false;
    this.outer.emit({isVisible:false,Option:"CancelSelectCustomer"});
  }

   handleOk(): void {

    if(this.CheckedCustomerNo=='')
    {
      this.message.error('Please select at least one customer !', { nzDuration: 3000 });
      return;
    }
    //console.info(this.CheckedCustomerNo);
    this.isOkLoading=true;
    this.ShareService.GetCustomerByNo(this.CheckedCustomerNo).do(()=>{}).subscribe(r=>{
        this.outer.emit({
          isVisible:false,
          Option:"ConfirmSelectCustomer",
          result:"sucess",
          Data:{
            Customer:r
          }});
        this.isOkLoading=false;
      },err=>{
          //返回报错时关闭loading
          this.isOkLoading=false;
      })
    
   }

}
