import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { NzMessageService, NzNotificationService, NzModalService } from 'ng-zorro-antd';
//import { LocalStorageService } from 'ngx-webstorage';

// 拦截器
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private message: NzMessageService,
    private notification: NzNotificationService,
    private modalService: NzModalService
    //private storage: LocalStorageService
  ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const token = localStorage.getItem("MCTApiToken");
    // if (token) { // 如果有token，就添加
    //   req = req.clone({
    //     setHeaders: {
    //       MCTApiToken: token
    //     }
    //   });
    // }
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) { // 这里是返回，可通过event.body获取返回内容
          // event.body
        }
      }, error => { // 统一处理所有的http错误
        if (error instanceof HttpErrorResponse) {
          if (error.status == 401) {
            this.message.remove();
            this.message.warning(error.error.message ? error.error.message : error.message, { 'nzDuration': 3000 });
            localStorage.removeItem("token");
            window.location.href = '#/login/default';
          }
          else if (error.status == 403) {
            this.message.remove();
            this.message.warning(error.error.message ? error.error.message : error.message, { 'nzDuration': 3000 });            
          }
          else if (error.status == 500) {
            this.modalService.error({
              nzTitle: 'Error',
              nzContent: '请求失败！'
            });
          } else if (error.status == 504) {
            this.modalService.error({
              nzTitle: 'Error',
              nzContent: '当前的网络不稳定！'
            });
          } else {
            location.href = '#/error?ErrorMessage=' + encodeURIComponent(error.error.message ? error.error.message : error.message);
            // this.modalService.error({
            //   nzTitle: 'Error',
            //   nzContent: error.error.message ? error.error.message : error.message
            // });
          }
        } else {
          this.modalService.error({
            nzTitle: 'Error',
            nzContent: '网络请求错误！'
          });
        }
      })
    )
  }
}