import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../environments/environment';
import * as moment from 'moment';
import { NgModel } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
registerLocaleData(en);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';

  constructor(
    private http: HttpClient,
    private router: Router
  ) {}

  ngOnInit() {
  }
  
}
