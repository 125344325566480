import { Component,ViewChild, OnInit,TemplateRef,Input, Output, EventEmitter} from '@angular/core';
import { NzNotificationService,NzModalService,NzSubMenuComponent  } from 'ng-zorro-antd';
import { Router,NavigationEnd,ActivatedRoute  } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import {filter, map} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import { MiddleService } from './middle.service';
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-middle',
  templateUrl: './middle.component.html',
  styleUrls: ['./middle.component.css'] ,
  
})
export class MiddleComponent implements OnInit  { 
  @Input() params:any;

  @ViewChild('NotificationTemplate') NotificationTemplate:TemplateRef<{}>;
  HasPermission=false;
  CurrentPermissions=[];
 
  ModuleTitle=null;//模块标题
  SubTitle=null;//子标题
  
  IsErrorModule=false;
  

  constructor(
    private http: HttpClient,
    private router: Router, 
    private sanitizer: DomSanitizer,
    private notification: NzNotificationService,
    private modalService :NzModalService,
    private route:ActivatedRoute,
    private middleService:MiddleService
  ) {

   }

  ngOnInit() {
    const base = this;
      // 切换路由置顶
      document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  // //请求Token后回调方法
  // GetMctTokenThen(callback){
  //   this.middleService.GetMCTToken().subscribe(p => {
  //     //设置Token
  //     localStorage.setItem('MCTApiToken', p);
  //     console.info(localStorage.getItem('MCTApiToken'));
  //     if(callback.sucess)
  //     {
  //       callback.sucess();
  //     }
  //   },err=>{
  //     if(callback.err)
  //     {
  //       callback.err();
  //     }
  //   });
  // }

  ngDoCheck():void{
    var _thisObj=this;
      if(_thisObj.params.CurrentPermissions && !_thisObj.HasPermission)
      {
        _thisObj.CurrentPermissions=_thisObj.params.CurrentPermissions;
        _thisObj.HasPermission=true;
      }
  }
 
}
