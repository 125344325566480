import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { AbpApiService, Result } from '../core/services/abp-api-service';
import { ValidFormModel } from '../Model/common.model';
import { PagedData } from '../Model/page.model';
import { NzMessageService } from 'ng-zorro-antd';

@Injectable()
export class ShareService extends AbpApiService {
    constructor(
        protected http: HttpClient,
        private Nzmessage: NzMessageService
    ) { super(http, Nzmessage); }

    /*获取所有需配置列表表头*/
    public HeaderList(params: object): Observable<PagedData<any>> {
        return this.abpPost<PagedData<any>>(this.SERVER_URL + "/api/Picker/GetSetTableHeaderList", this.parseParams(params), null, {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'Accept': '*/*'
        });
    }

    /**
   * 保存表头
   * @param params
   */
    public SaveToUserFields(params: object, TableTypeID: number): Observable<ValidFormModel> {
        return this.abpPost<ValidFormModel>(this.SERVER_URL + "/api/Picker/SaveToUserFields/" + TableTypeID, this.parseParams(params), null, {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'Accept': '*/*'
        });
    }

    /**
     * PSS 获取详情
     */
    // public GetAreaNodes(): Observable<any> {
    //     return this.abpGet<any>(this.SERVER_URL + "/api/Report/GetAreaNodes");
    // }
    public GetAreaNodes(params: object): Observable<PagedData<any>> {
        return this.abpPost<PagedData<any>>(this.SERVER_URL + "/api/Report/GetAreaNodes", this.parseParams(params), null, {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'Accept': '*/*'
        });
    }

    /**
 * 按权限获取 Customer列表
 * @param params
 */
    public GetPageCustomerList(params: object): Observable<PagedData<any>> {
        return this.abpPost<PagedData<any>>(this.SERVER_URL + "/api/Customer/GetPageCustomerList", this.parseParams(params), null, {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'Accept': '*/*'
        });
    }
    /**
     * 根据CustomerNo获取客户信息
     */
    public GetCustomerByNo(CustomerNo: string): Observable<any> {
        return this.abpGet<any>(this.SERVER_URL + "/api/Customer/GetCustomerByNo/" + CustomerNo);
    }
}