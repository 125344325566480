import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TempleteSimpleEditComponent } from './templete-simple-edit/templete-simple-edit.component';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { ReactiveFormsModule,FormsModule } from '@angular/forms'
import { CommonSettingHeaderComponent } from './common-setting-header/common-setting-header.component';
import { ShareService } from "./share.service";
import { CommonAreaSelectorComponent } from './common-area-selector/common-area-selector.component';
import { AuthInterceptor } from '../app-auth-interceptor.module';
import {SelectCustomerListComponent} from'./select-customer-list/select-customer-list.component';

@NgModule({
  imports: [
    CommonModule,
    NgZorroAntdModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule
  ],
  declarations: [
    TempleteSimpleEditComponent,
    CommonSettingHeaderComponent,
    CommonAreaSelectorComponent,
    SelectCustomerListComponent
  ],
  providers:[
    ShareService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
  ,exports:[
    CommonModule,
    //需在此配置组件，才可在外部使用
    TempleteSimpleEditComponent,
    CommonSettingHeaderComponent,
    CommonAreaSelectorComponent,
    SelectCustomerListComponent
  ]
})
export class ShareModule { }
