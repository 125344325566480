import { Injectable } from '@angular/core';
import { ReplaySubject,Observable  } from 'rxjs';

@Injectable()
//组件间通信
export class CommunicateService {

  constructor() { }

  private _sendMessage: ReplaySubject<any> = new ReplaySubject(null, 1000); // 1s后清除消息

  //向其他组件发送信息 
  //@param message 需要发送的信息
  //@returns {Observavle<any>}
  public  sendMessage(message: any) { 
    this._sendMessage.next(message); 
  } 

  //订阅其他组件发送来的消息 
  //@returns {Observalue<any>} */ 
  public getMessage(): Observable <any>{
    return this._sendMessage.asObservable(); 
  }  
}
