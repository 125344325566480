import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ShareService } from "../share.service";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import { Router } from '@angular/router';
import { NzMessageService, NzModalService, NzTreeComponent, NzTreeNode } from 'ng-zorro-antd';
import { ValidFormModel } from '../../Model/common.model';

@Component({
  selector: 'app-common-area-selector',
  templateUrl: './common-area-selector.component.html',
  styleUrls: ['./common-area-selector.component.css']
})
export class CommonAreaSelectorComponent implements OnInit {
  @Input() params: any;
  @Output() private outer = new EventEmitter<any>();
  @ViewChild('regionTree') regionTree: NzTreeComponent;

  validateForm: FormGroup;
  Nodes: NzTreeNode[];
  checkedProvinceIds = [];
  checkedProvinceNames = [];
  checkedNodeKeys = [];
  isOkLoading = false;
  CheckedKeys = [];
  RegionName = null;
  SubRegionName = null;

  constructor(
    private shareService: ShareService,
    private fb: FormBuilder,
    private router: Router,
    private message: NzMessageService,
    private modalService: NzModalService
  ) { }

  ngOnInit() {
    
    this.CheckedKeys = this.params.CheckedKeys;
    //console.info(this.CheckedKeys);
    this.validateForm = this.fb.group({});
    this.RegionName = this.params.RegionName ? this.params.RegionName : null;
    this.SubRegionName = this.params.SubRegionName ? this.params.SubRegionName : null;
    this.shareService.GetAreaNodes({ 
      RegionName: this.RegionName,
      SubRegionName:this.SubRegionName
     }).subscribe(m => {
      if (m) {
          
        this.Nodes = this.ToTree(m.items, "0");
        //console.info(this.Nodes);
      }
    }, err => {
    })
  }

  ToTree(treeData, parentId) {
    var _this = this;
    const treeArr = [];
    treeData.forEach(function (item) {
      if (item.Parentkey == parentId) {
        const children = _this.ToTree(treeData, item.key);
        treeArr.push(new NzTreeNode({
          key: item.key,
          title: item.title,
          isLeaf: children.length == 0,
          children: children,
          expanded: item.key == "Root" ? true : false,
        }));
      }

    });

    return treeArr;
  }

  //获取选中节点 keys (只取叶子节点)
  GetCheckedNodes(list: NzTreeNode[]) {
    var base = this;
    list.forEach(function (item, index) {
      if (item.isLeaf) {
        //Province层节点 Key值：RegionId_SubRegionId_ProvinceId
        base.checkedProvinceIds.push(item.key.split('_')[2]);
        base.checkedProvinceNames.push(item.title);
        base.checkedNodeKeys.push(item.key);
      } else {
        base.GetCheckedNodes(item.children);
      }
    });
  }

  handleOk(): void {

    var base = this;
    base.isOkLoading = true;
    //获取节点key
    base.GetCheckedNodes(this.regionTree.getCheckedNodeList());

    //console.info(base.checkedProvinceIds.join(","));
    //console.info(base.checkedProvinceNames.join(","));
    //console.info(base.checkedNodeKeys.join(","));
    base.isOkLoading = false;
    base.outer.emit({
      isVisible: false,
      Option: "SelectAreaOk",
      Data: {
        ProvinceIds: base.checkedProvinceIds,
        ProvinceNames: base.checkedProvinceNames,
        checkedNodeKeys: base.checkedNodeKeys
      }
    });
  }

  handleCancel() {
    this.isOkLoading = false;
    this.outer.emit({ isVisible: false, Option: "CancelSelectArea" });
  }
}
